import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueYandexMetrika from 'vue-yandex-metrika'
// import * as Sentry from '@sentry/vue'
import lazyImage from '@/directives/lazyImage'
// import { allowedErrorsMessages, exceptionTransactions401 } from '@/helpers/sentryExceptions'
import VueGtm from '@gtm-support/vue2-gtm'
import Toast from 'vue-toastification'
// Не удалять пушер, без него не работает!!!
// eslint-disable-next-line
import pusher from '@/sockets/pusher'
import VueMoment from 'vue-moment'
import VCalendar from 'v-calendar'
import App from './App.vue'
import './assets/css/style.scss'
import router from './router'
import { http } from './http'
import config from './config'
import HomeLayout from './layouts/HomeLayout.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'
import BalanceWithMenuLayout from './layouts/BalanceWithMenuLayout.vue'
import EmptyLayout from './layouts/EmptyLayout.vue'
import BalanceOnly from './layouts/BalanceOnly.vue'
import LogoOnly from './layouts/LogoOnly.vue'
import ArrowBackLayout from './layouts/ArrowBackLayout.vue'
import '@/helpers/validationRules'

const options = {
	position: 'bottom-center',
	timeout: 2000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: 'button',
	icon: false,
	transition: 'Vue-Toastification__fade',
	maxToasts: 1,
	newestOnTop: true
}

Vue.use(Toast, options)

const isProductionMode = import.meta.env.PROD
Vue.directive('lazy', lazyImage)
Vue.component('Empty', EmptyLayout)
Vue.component('Default', DefaultLayout)
Vue.component('Home', HomeLayout)
Vue.component('BalanceWithMenu', BalanceWithMenuLayout)
Vue.component('BalanceOnly', BalanceOnly)
Vue.component('LogoOnly', LogoOnly)
Vue.component('ArrowBack', ArrowBackLayout)

Vue.use(VueYandexMetrika, {
	id: import.meta.env.VITE_YA_METRIKA_ID,
	router,
	env: process.env.NODE_ENV,
	options: {
		clickmap: true,
		trackLinks: true,
		accurateTrackBounce: true,
		webvisor: true
	}
})

if (isProductionMode) {
	// Sentry.init({
	// 	Vue,
	// 	dsn: 'https://c5b7fcfba3c247d08416470a6a0e2390@sentry.via-mobi.com/14',
	// 	logErrors: true,
	// 	integrations: [
	// 		new Sentry.BrowserTracing({
	// 			routingInstrumentation: Sentry.vueRouterInstrumentation(router)
	// 		}),
	// 		new Sentry.Replay()
	// 	],
	// 	beforeBreadcrumb(breadcrumb) {
	// 		if (breadcrumb.category === 'fetch' && breadcrumb.data.url.include(['yandex', 'googletagmanager'])) {
	// 			return null
	// 		}
	// 		return breadcrumb
	// 	},
	// 	beforeSend(event, hint) {
	// 		const { type, value } = event.exception.values[0]
	// 		const { transaction } = event.tags
	//
	// 		if (type === 'AxiosError' && value === 'Request failed with status code 403') {
	// 			const { message } = hint.originalException.response.data
	// 			const isExceptionError = allowedErrorsMessages.find((rg) => rg.test(message))
	//
	// 			if (isExceptionError) {
	// 				return null
	// 			}
	//
	// 			if (hint.originalException.response.data?.redirect_to) {
	// 				return null
	// 			}
	// 		}
	//
	// 		if (type === 'AxiosError' && value === 'Request failed with status code 401') {
	// 			if (exceptionTransactions401.includes(transaction)) {
	// 				return null
	// 			}
	// 		}
	//
	// 		return event
	// 	},
	// 	ignoreErrors: ['Navigation cancelled', 'NavigationDuplicated', 'MyMood не подключён'],
	// 	denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
	// 	// Performance Monitoring
	// 	tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
	// 	// Session Replay
	// 	replaysSessionSampleRate: 0.1,
	// 	replaysOnErrorSampleRate: 1.0
	// })

	Vue.use(VueGtm, {
		id: import.meta.env.VITE_GTM_ID,
		defer: false,
		enabled: true,
		debug: false,
		loadScript: true,
		vueRouter: router,
		trackOnNextTick: false
	})
}

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

Vue.use(pinia)

Vue.use(VueMoment)

Vue.use(VCalendar, {
	componentPrefix: 'vc'
})

new Vue({
	router,
	http,
	config,
	render: (h) => h(App)
}).$mount('#app')
