import { uploadImage, uploadVideo, uploadAudio } from '@/http/messenger/uploadPaidContent'
import { createPaidMessage } from '@/http/messenger/createPaidMessage'
import { setCoverForPaidMessage } from '@/http/messenger/setCoverForPaidMessage'
import { updatePaidMessage } from '@/http/messenger/updatePaidMessage'
import { publishPaidMessage } from '@/http/messenger/publishPaidMessage'
import { markRead } from './markRead'
import { getThreadsList } from './privatesList'
import { search } from './search'
import { locateThread } from './locate'
import { initializeThread } from './privates'
import { getMessages } from './getMessages'
import { sendTextMessage, sendImageMessage, sendVideoMessage } from './sendMessage'
import { editMessage } from './editMessage'
import { getReactions, setReaction, removeReaction } from './reaction'
import { getRecommendedListConsumer } from './recommendedListConsumer'
import { getRecommendedListGirl } from './recommendedListGirl'

export default {
	getMessages,
	getThreadsList,
	getRecommendedListConsumer,
	getRecommendedListGirl,
	search,
	locateThread,
	initializeThread,
	sendTextMessage,
	sendImageMessage,
	sendVideoMessage,
	editMessage,
	getReactions,
	setReaction,
	removeReaction,
	markRead,
	uploadImage,
	uploadVideo,
	uploadAudio,
	createPaidMessage,
	updatePaidMessage,
	setCoverForPaidMessage,
	publishPaidMessage
}
