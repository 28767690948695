<template>
	<div>
		<template v-if="!girlVerificationApproved">
			<button
				type="button"
				class="post-status-text text-red"
				v-if="girlNeedsVerification"
				@click="$emit('show-verification-needs-dialog')"
			>
				Верифицировать
			</button>
			<button
				type="button"
				class="post-status-text"
				v-if="girlVerificationRejected"
				@click="$emit('show-verification-rejected-dialog')"
			>
				Верифицировать
			</button>
			<button
				type="button"
				class="post-status-text"
				v-if="girlVerificationInProcess"
				@click="$emit('show-verification-process-dialog')"
			>
				На верификации
			</button>
		</template>
		<template v-else>
			<span v-if="postInModeration" class="post-status-text">На модерации</span>
			<span v-if="postScheduled" class="post-status-text success">Подтвержден</span>
			<button
				type="button"
				v-if="postRejected"
				class="post-status-text fail"
				@click="$emit('show-post-rejected-dialog')"
			>
				Отклонен
				<svg-icon name="info" size="16px" />
			</button>
		</template>
	</div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'PostStatusForGirl',
	components: {
		SvgIcon
	},
	props: {
		audience: { type: String, default: '' },
		publication_status: { type: String, default: '' },
		verificationStatus: { type: String, default: '' }
	},
	computed: {
		girlVerificationApproved() {
			return this.verificationStatus === 'approved'
		},
		girlNeedsVerification() {
			return this.verificationStatus === 'needs'
		},
		girlVerificationInProcess() {
			return this.verificationStatus === 'in_process'
		},
		girlVerificationRejected() {
			return this.verificationStatus === 'rejected'
		},
		postInModeration() {
			return this.publication_status === 'in_moderation'
		},
		postRejected() {
			return this.publication_status === 'rejected'
		},
		postScheduled() {
			return this.publication_status === 'scheduled'
		}
	}
}
</script>

<style lang="scss">
.post-status {
	&-text {
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		color: rgba(#fff, 50%);
		background: transparent;
		border: none;

		&.success {
			color: #2ba563;
		}

		&.fail {
			color: #ed3232;
		}
	}
}
</style>
