import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import router from '@/router'
import { useModalStore } from '@/stores/modal'

const http = axios

const subscribeErrors = {
	'Unsubscribed: insufficient funds':
		'На балансе не хватает денег, чтобы подключить MyMood. Пополните баланс и повторите попытку.',
	'Unsubscribed: subscription failed':
		'MyMood не подключён. Попробуйте ещё раз позднее или обратитесь в поддержку по бесплатному номеру 8 800 700-06-11.'
}

axios.interceptors.request.use((config) => {
	config.redirect = 'manual'
	return config
})

http.interceptors.response.use(
	async (config) => {
		if (import.meta.env.DEV) {
			// console.log(config.config.url, config.config.data, config.data.data);
			return config
		}
		return config
	},
	async (error) => {
		if (error.response) {
			if (error.response.status === 503) {
				await router.push({ name: 'service-unavailable' })
			}

			if (error.response.status === 401) {
				window.location.replace(import.meta.env.VITE_AUTH_REDIRECT)
			}

			if (error.response.status === 403) {
				if (error.response.data.redirect_to) {
					window.location.href = error.response.data.redirect_to
					return Promise.reject(error)
				}

				const errorMessage = error.response.data.message
				if (errorMessage.includes('Subscription Waiting')) {
					const errorRouteName = 'error-subscription-waiting-only-one-girl'
					const messageParts = errorMessage.split(' ')
					const girlNickname = messageParts[messageParts.length - 1]
					if (errorRouteName !== router.currentRoute.name) {
						await router.push({
							name: errorRouteName,
							params: { nickname: girlNickname }
						})
					}
				} else if (errorMessage.includes('Subscription Failed')) {
					const messageParts = errorMessage.split(' ')
					const temafonCode = messageParts[messageParts.length - 1]
					await router.push({
						name: 'error-subscription-failed-only-one-girl',
						params: { code: temafonCode }
					})
				} else if (errorMessage.includes('unsubscribed to only one girl')) {
					window.location.href = import.meta.env.VITE_SUBS_URL
				} else if (Object.keys(subscribeErrors).includes(errorMessage)) {
					await router.push({
						name: 'error-subscribe',
						params: {
							message: subscribeErrors[errorMessage]
						}
					})
				} else {
					window.location.replace(import.meta.env.VITE_AUTH_REDIRECT)
				}
			}
		}

		if (error.code === 'ERR_NETWORK') {
			useModalStore().showNetworkError()
		}

		return Promise.reject(error)
	}
)

http.defaults.baseURL = import.meta.env.VITE_API_URL

Vue.use(VueAxios, http)

export { http }
export default {
	root: import.meta.env.VITE_API_URL
}
