<template>
	<div
		class="post"
		:class="{ 'post-background': background }"
		:id="id"
		:data-publication-status="publication_status"
		:data-audience="audience"
	>
		<div class="post-header">
			<router-link :to="girlProfileLink" class="post-author-info" :data-girl-id="author.id">
				<avatar-circle :image-path="author.avatar" />
				<div class="post-data flex column center">
					<div class="post-name" @click="onClickNickname">
						{{ author.nickname }}
						<span v-if="author?.has_in_fans_current_consumer" class="name-badge" />
					</div>
					<div class="post-labels">
						<div v-if="isPublishedPost" class="post-label post-release-date">{{ published_text }}</div>
						<div v-else-if="isScheduledPost && isOwnPost" class="post-label post-planned-date">
							<svg-icon name="clock-plan" size="10px" />
							{{ shouldBePublishedAt }}
						</div>
						<div class="post-label post-audience" v-if="postForFans">
							<svg-icon name="star" size="10px" />
							Для фанатов
						</div>
					</div>
				</div>
			</router-link>
			<subscribe-button
				:id="author.id"
				v-if="isConsumer"
				btn-class="post-subscribe"
				:is-subscribed="author.has_in_fans_current_consumer"
				:is-waiting-subscription-result="isWaitingSubscriptionResult"
				:is-subscribe-renewable="isSubscribeRenewable"
				:subscribed-till="author.girl_subscription_expires_at"
			/>
			<button type="button" class="post-options-button" v-if="isOwnPost" @click="showPostOptionsDialog = true">
				<svg-icon name="ellipsis-vertical" />
			</button>
		</div>
		<text-with-hashtags-and-mentioned
			class="post-description"
			:text="description"
			:hashtags="hashtags"
			:mentioned="mentioned"
			:preview-letters-count="detailPage ? description.length : 80"
		/>
		<div
			v-if="attachments"
			class="post-media"
			style="position: relative"
			@click="goToDetailPage"
			@contextmenu="(e) => e.preventDefault()"
		>
			<component
				:is="`post-media-${type}`"
				v-bind="attachments"
				:id="id"
				:is-owner="isOwnPost"
				@videoLoaded="onVideoLoaded"
			/>
			<div v-if="isBluredLoader" class="blur flex center align">
				<div class="i-spinner rotating post-subscribe-spinner" />
			</div>
			<div
				v-if="isLocked"
				:class="['lock big', { 'lock-blurred-big': !hasBlurredImage }]"
				@click="openSubscriptionPage"
			/>
		</div>
		<post-status-for-girl
			class="post-status"
			v-if="isGirl && !isPublishedPost"
			:audience="audience"
			:publication_status="publication_status"
			:verification-status="verificationStatus"
			@show-post-rejected-dialog="showPostRejectedDialog = true"
			@show-verification-needs-dialog="showVerificationNeedsDialog = true"
			@show-verification-process-dialog="showVerificationProcessDialog = true"
			@show-verification-rejected-dialog="showVerificationRejectedDialog = true"
		/>
		<div v-if="isPublishedPost" class="post-like-panel">
			<div class="flex row space">
				<div class="post-actions">
					<post-like v-if="isConsumer" @clickLike="showPopUpAboutLike" :amount="total_consumer_likes" :post-id="id" />
					<router-link
						:class="[
							'post-comment-link',
							{ 'post-comment-link-pink': !girl_liked_any_comment && total_comments > 0 && isOwnPost }
						]"
						:to="postPageComments"
					>
						<svg-icon name="message" size="19px" />
						<span v-if="total_comments" class="post-like-text">{{ total_comments }}</span>
					</router-link>
					<!--					<div>-->
					<!--						<svg-icon name="rub" size="20px" />-->
					<!--						Чаевые-->
					<!--					</div>-->
				</div>
			</div>
			<router-link :to="{ name: 'post-likes', params: { id } }" class="post-like-counter">
				<span :total-like-count="total_likes" class="post-total-likes">Нравится: {{ total_likes }}</span>
			</router-link>
		</div>
		<slot v-if="isPublishedPost" name="comments">
			<div v-if="comment" class="post-comments">
				<comment-component
					:preview-letters-count="70"
					:nickname="comment.consumer_nickname"
					:text="comment.text"
					:avatar="comment.consumer_avatar"
					:author-id="comment.consumer_id"
					:consumer-has-girl-subscription="comment?.consumer_has_girl_subscription"
					:liked-avatar="author.avatar"
					:liked-at-text="girl_liked_any_comment ? ' ' : ''"
					:mentioned="comment.mentioned"
					:post-id="id"
				/>
				<router-link :to="postPageComments" class="post-page-url">
					<template v-if="total_comments"> Смотреть все комментарии </template>
					<template v-if="total_comments === 0 && isConsumer">Прокомментировать</template>
				</router-link>
			</div>
		</slot>
		<girl-verification-needs-dialog
			v-if="showVerificationNeedsDialog"
			@close-dialog="showVerificationNeedsDialog = false"
		/>
		<dialog-component v-if="showVerificationProcessDialog" @close="showVerificationProcessDialog = false">
			<template #header-title>Верификация. Почти готово.</template>
			<p class="status-dialog-text">
				Нужно немного подождать, пока мы проверим данные. Обычно процесс занимает не более 8 часов. После этого тебе
				станет доступен просмотр чужих постов, а твои собственные посты опубликуются автоматически.
			</p>
			<button-component :loading="isSending" @click="showVerificationProcessDialog = false"> Отлично </button-component>
		</dialog-component>
		<dialog-component v-if="showVerificationRejectedDialog" @close="showVerificationRejectedDialog = false">
			<template #header-title>Требуется верификация.</template>
			<p class="status-dialog-text">Мы не смогли убедиться, что ты — настоящая. Попробуй повторить шаги верификации.</p>
			<button-component :loading="isSending" @click="startVerification"> Повторить верификацию </button-component>
		</dialog-component>
		<dialog-component v-if="showPostRejectedDialog" @close="showPostRejectedDialog = false">
			<template #header-title>Пост отклонен</template>
			<p v-if="rejection_reason" class="status-dialog-text text-red">От модератора: {{ rejection_reason }}</p>
			<p class="status-dialog-text">Для повторной модерации необходимо отредактировать пост</p>
		</dialog-component>
		<dialog-component v-if="showFanPostInfoDialog" @close="showFanPostInfoDialog = false">
			<template #header-title>Это пост для фанатов.</template>
			<p class="status-dialog-text">Его могут увидеть только подписчики этого автора. И&nbsp;сам автор, конечно.</p>
		</dialog-component>
		<dialog-component v-if="showInfoAboutLike" @close="showInfoAboutLike = false">
			<template #header-title>Чем сильнее симпатия, тем больше лайков!</template>
			<p class="status-dialog-text">
				Каждому посту можно поставить любое количество лайков. <span class="iconInfo iconInfo--heart" /><span
					class="iconInfo iconInfo--heart"
				/><span class="iconInfo iconInfo--heart" /> <br /><br />
				Чем больше лайков, тем чаще пост появляется в рекомендациях — так автор становится популярным.
				<span class="iconInfo iconInfo--princess" /> <br /><br />
				Самые активные пользователи получают награды от благодарных авторов. 💎 <br /><br />
				Заходите каждый день, чтобы получить как можно больше лайков и собрать коллекцию эксклюзивных наград,
				посвященных именно вам! <span class="iconInfo iconInfo--cup" /><br />
			</p>
		</dialog-component>
		<dialog-component v-if="showPostOptionsDialog" @close="showPostOptionsDialog = false">
			<template #header-title />
			<ul class="post-options-list">
				<li class="post-options-item">
					<button type="button" class="post-options-action" @click="onEdit">Редактировать</button>
				</li>
				<li class="post-options-item" v-if="publication_status === 'rejected'">
					<button type="button" class="post-options-action text-red" @click="onDelete">Удалить пост</button>
				</li>
			</ul>
		</dialog-component>
	</div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useCreatePostStore } from '@/stores/createPost'
import { useProfileStore } from '@/stores/profile'
import { useModalStore } from '@/stores/modal'
import { usePostStore } from '@/stores/post'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { observer } from '@/helpers/postViewObserver'
import CommentComponent from '@/components/CommentComponent.vue'
import PostMediaCarousel from '@/components/PostMediaCarousel.vue'
import PostMediaVideo from '@/components/PostMediaVideo.vue'
import PostMediaImage from '@/components/PostMediaImage.vue'
import PostLike from '@/components/PostLike.vue'
import SubscribeButton from '@/components/SubscribeButton.vue'
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue'
import AvatarCircle from '@/components/AvatarCircle.vue'
import PostStatusForGirl from '@/components/PostStatusForGirl.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import GirlVerificationNeedsDialog from '@/components/dialogs/GirlVerificationNeedsDialog.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { dayMonthYearNumeric, hoursMinutes } from '@/helpers/dateFormat'

export default {
	name: 'PostComponent',
	components: {
		GirlVerificationNeedsDialog,
		ButtonComponent,
		DialogComponent,
		PostStatusForGirl,
		TextWithHashtagsAndMentioned,
		AvatarCircle,
		SubscribeButton,
		CommentComponent,
		PostMediaCarousel,
		PostMediaVideo,
		PostMediaImage,
		PostLike,
		SvgIcon
	},
	props: {
		author: {
			type: Object,
			default: () => {}
		},
		comment: {
			type: Object,
			default: () => {}
		},
		total_likes: { type: Number, default: 0 },
		total_comments: { type: Number, default: 0 },
		total_consumer_likes: { type: Number, default: 0 },
		description: { type: String, default: '' },
		hashtags: { type: Array, default: () => [] },
		mentioned: {
			type: Object,
			default: () => {}
		},
		published_text: { type: String, default: '' },
		id: { type: Number, default: 0 },
		attachments: {
			type: Object,
			default: () => {}
		},
		audience: { type: String, default: '' },
		visibility: { type: String, default: '' },
		girl_liked_any_comment: Boolean,
		rejection_reason: { type: String, default: '' },
		publication_status: { type: String, default: '' },
		onClickNicknameFunction: { type: Function, default: undefined },
		should_be_published_at: { type: String, default: '' },
		can_be_scheduled: { type: Boolean, default: false },
		background: { type: Boolean, default: true },
		detailPage: { type: Boolean, default: false }
	},
	data() {
		return {
			showVerificationNeedsDialog: false,
			showVerificationProcessDialog: false,
			showVerificationRejectedDialog: false,
			showPostRejectedDialog: false,
			showFanPostInfoDialog: false,
			showPostOptionsDialog: false,
			isSending: false,
			showInfoAboutLike: false
		}
	},
	computed: {
		...mapState(useProfileStore, {
			consumerId: 'id',
			hasCardPayment: 'hasCardPayment',
			totalLikes: 'total_likes'
		}),
		...mapWritableState(useCreatePostStore, ['post']),
		...mapState(useVideoUploadStore, ['progress']),
		shouldBePublishedAt() {
			const date = dayMonthYearNumeric(this.should_be_published_at.replace(/-/g, '/'))
			const time = hoursMinutes(this.should_be_published_at.replace(/-/g, '/'))
			return `${date} в ${time}`
		},
		verificationStatus() {
			const girlProfileStore = useGirlProfileStore()
			return girlProfileStore.verification_status
		},
		isOwnPost() {
			const girlProfileStore = useGirlProfileStore()
			const currentGirlId = girlProfileStore.id
			return this.postAuthorId === currentGirlId
		},
		isLocked() {
			return this.visibility === 'lock' && !this.isWaitingSubscriptionResult
		},
		isBluredLoader() {
			return this.visibility === 'lock' && this.isWaitingSubscriptionResult
		},
		type() {
			if (this.attachments && this.attachments.photos && this.attachments.type === 'photo') {
				return this.attachments.photos.length > 1 ? 'carousel' : 'image'
			}
			return 'video'
		},
		postAuthorId() {
			return this.author.id
		},
		girlProfileLink() {
			return {
				name: 'author-profile',
				params: { nickname: this.author.nickname, id: String(this.postAuthorId) }
			}
		},
		isPublishedPost() {
			return this.publication_status === 'published'
		},
		isScheduledPost() {
			return this.should_be_published_at
		},
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		postPage() {
			return { name: 'post', params: { id: this.id } }
		},
		postPageComments() {
			return { name: 'post', params: { id: this.id }, hash: '#commentsList' }
		},
		anyCommentNotLiked() {
			return this.total_comments > 0 && !this.girl_liked_any_comment
		},
		hasAttachedFile() {
			if (this.attachments.video && this.type === 'video') {
				return this.attachments.video.file !== null
			}
			if (this.attachments.photos) return this.attachments.photos.length > 0
			return false
		},
		isWaitingSubscriptionResult() {
			return this.author.waiting_subscription_result
		},
		isSubscribeRenewable() {
			return !this.author.stopped_subscription_renewal
		},
		subLink() {
			const host = import.meta.env.VITE_API_URL
			if (this.audience === 'all') {
				return `${host}consumer/service/subscribe-from-post?token=${this.$auth.token()}`
			}
			if (this.postForFans) {
				return `${host}consumers/${this.consumerId}/girls/${this.postAuthorId}/subscribe`
			}
			return ''
		},
		postForFans() {
			return this.audience === 'fans'
		},
		hasBlurredImage() {
			if (this.attachments?.type === 'video') {
				const { blurred_thumbnail, blurred_image } = this.attachments.video.preview
				return blurred_thumbnail || blurred_image?.url
			}
			const { blurred_thumbnail, blurred_image } = this.attachments.photos[0]
			return blurred_thumbnail || blurred_image?.url
		}
	},
	methods: {
		...mapActions(useCreatePostStore, { setPost: 'setPost', setMode: 'setMode' }),
		...mapActions(useModalStore, { openVerificationDialog: 'openVerificationDialog' }),
		...mapActions(usePostStore, { deletePost: 'deletePost' }),
		showPopUpAboutLike() {
			if (this.totalLikes === 0) {
				this.$metrika.reachGoal('onbording-like')
				this.showInfoAboutLike = true
			}
		},
		onVideoLoaded() {
			if (this.detailPage) {
				this.$emit('videoLoaded')
			}
		},
		goToDetailPage() {
			if (this.detailPage) {
				return
			}
			if (this.publication_status === 'published') {
				this.$router.push(this.postPage)
			}
		},
		openSubscriptionPage(e) {
			e.stopPropagation()
			if (this.isConsumer) {
				window.location.href = this.subLink
			}
			if (this.isGirl) {
				switch (this.verificationStatus) {
					case 'needs': {
						this.showVerificationNeedsDialog = true
						break
					}
					case 'in_process': {
						this.showVerificationProcessDialog = true
						break
					}
					case 'rejected': {
						this.showVerificationRejectedDialog = true
						break
					}
					case 'approved': {
						this.showFanPostInfoDialog = true
						break
					}
					default: {
						break
					}
				}
			}
		},
		onClickNickname(e) {
			e.preventDefault()
			if (this.onClickNicknameFunction) {
				this.onClickNicknameFunction(this.author.nickname)
				return
			}
			this.$router.push(this.girlProfileLink)
		},
		onEdit() {
			this.showPostOptionsDialog = false
			if (this.progress > 0) {
				return
			}
			let post = {}
			if (this.type === 'image') {
				post = {
					photo_id: this.attachments.photos[0].id,
					photo: this.attachments.photos[0].url,
					description: this.description,
					audience: this.audience,
					should_be_published_at: this.should_be_published_at,
					can_be_scheduled: this.can_be_scheduled
				}
			}
			if (this.type === 'video') {
				post = {
					video_id: this.attachments.video.id,
					video: this.attachments.video.file,
					video_preview_id: this.attachments.video.preview.id,
					description: this.description,
					audience: this.audience,
					should_be_published_at: this.should_be_published_at,
					can_be_scheduled: this.can_be_scheduled
				}
			}
			this.setPost(post, this.id, 'edit')
		},
		async onDelete() {
			this.showPostOptionsDialog = false
			this.isSending = true
			await this.deletePost(this.id)
			this.showPostRejectedDialog = false
			this.isSending = false
		},
		startVerification() {
			this.openVerificationDialog()
			this.showVerificationNeedsDialog = false
			this.showVerificationRejectedDialog = false
		},
		observeViewEvent() {
			const element = document.getElementById(String(this.id))
			if (!this.isLocked) {
				observer.observe(element)
			}
		}
	},
	mounted() {
		if (this.isConsumer) {
			this.observeViewEvent()
		}
	}
}
</script>

<style lang="scss">
$padding-x: 15px;

.post {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	padding: 10px 0;
	scroll-margin-top: 50px;
	margin-bottom: 8px;
	border-radius: 12px;
	gap: 9px;

	&-background {
		background: #2c2d3780;
	}

	&-header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: flex-start;
		padding: 0 $padding-x;
	}

	&-author-info {
		display: flex;
		gap: 10px;
	}

	&-options {
		&-list {
			list-style-type: none;
		}

		&-item {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		&-action {
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			color: #fff;
			background: transparent;
			border: none;
			cursor: pointer;
			padding: 5px 0;
		}
	}

	&-description {
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		color: var(--white);
		padding: 0 $padding-x;
	}

	&-options-button {
		color: var(--text-gray);
		background: transparent;
		border: none;
		font-size: 20px;
		cursor: pointer;
		padding: 5px;
		transform: translateX(15px);
	}

	&-labels {
		display: flex;
		gap: 6px;
		padding: 2px 0;
		margin-top: 2px;
	}

	&-label {
		border-radius: 50px;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		color: #fff;
		padding: 0 4px;
		display: flex;
		align-items: center;
		gap: 3px;
	}

	&-audience {
		background: #1553f2;
	}

	&-planned-date {
		background: var(--red);
	}

	&-release-date {
		padding: 0;
		color: var(--text-gray);
	}

	&-status {
		padding: 0 $padding-x;
	}

	&-like-panel {
		padding: 0 $padding-x;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-actions {
		display: flex;
		color: #9b9ca6;
		gap: 10px;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;

		& > * {
			background: #2c2d37;
			height: 32px;
			display: flex;
			align-items: center;
			gap: 5px;
			padding: 0 11px;
			border-radius: 50px;
		}
	}

	&-total-likes {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: rgba(#fff, 50%);
	}

	&-comments {
		padding: 0 $padding-x;

		.comment-like {
			margin-top: 5px;
		}
	}

	&-page-url {
		margin-top: 5px;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: var(--text-gray);
		display: block;
	}
}

.status-dialog-text {
	margin-bottom: 26px;
	color: var(--text-gray);
	font-size: 16px;
	line-height: 20px;
}

.post-media {
	user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
}

.iconInfo {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url('@/assets/images/icons/sportsmedal.png');
}

.iconInfo--princess {
	background-image: url('@/assets/images/icons/princess.png');
}

.iconInfo--heart {
	background-image: url('@/assets/images/icons/heart.png');
}

.post-comment-link {
	color: #a69c9b;

	&-pink {
		color: #f81a5f;
	}
}
</style>
