import { http } from '@/http/index'

async function uploadImage(messageId, formData) {
	return http.post(`/paid-messages/${messageId}/images`, formData)
}

async function uploadVideo(messageId, formData) {
	return http.post(`/paid-messages/${messageId}/videos`, formData)
}

async function uploadAudio(messageId, formData) {
	return http.post(`/paid-messages/${messageId}/audios`, formData)
}

export { uploadImage, uploadVideo, uploadAudio }
