import { http } from '@/http/index'

async function editPostWithPhoto(postId, data) {
	return http.post(`/girls/posts/photo/${postId}`, data)
}

async function editPostWithVideo(postId, data) {
	return http.post(`girls/posts/video/${postId}`, data)
}

export { editPostWithPhoto, editPostWithVideo }
