<template>
	<div class="comment" :data-post-id="post?.id || postId">
		<div class="flex">
			<router-link v-if="showAvatar" :to="consumerProfileLink" class="comment-avatar">
				<avatar-circle size="30px" :image-path="avatar" />
			</router-link>
			<div class="w-100">
				<span @click="onClickNickname" class="comment-nickname">
					{{ nickname }}
					<span v-if="hasGirlSubscription" class="name-badge" />
					<span v-if="consumerHasGirlSubscription" class="name-badge" />
				</span>
				<br />
				<text-with-hashtags-and-mentioned
					class="comment-text"
					:text="text"
					:preview-letters-count="previewLettersCount"
					:mentioned="mentioned"
				/>
				<div class="release" v-if="createdAt">{{ createdAt }}</div>
			</div>
		</div>
		<slot name="append">
			<div v-if="likedAtText" class="comment-like" v-lazy.background="likedAvatar" />
		</slot>
	</div>
</template>

<script>
import AvatarCircle from '@/components/AvatarCircle.vue'
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue'

export default {
	name: 'CommentComponent',
	components: {
		AvatarCircle,
		TextWithHashtagsAndMentioned
	},
	props: {
		avatar: { type: String, default: '' },
		authorId: { type: Number, default: 0 },
		nickname: { type: String, default: '' },
		createdAt: { type: String, default: '' },
		text: { type: String, default: '' },
		likedAvatar: { type: String, default: '' },
		likedAtText: { type: String, default: '' },
		previewLettersCount: { type: Number, default: 0 },
		onClickNicknameFunction: { type: Function, default: null },
		mentioned: { type: Object, default: () => {} },
		post: { type: Object, default: () => {} },
		postId: {
			type: Number,
			default: 0
		}, // todo привести к одному виду, где-то передается пост, где-то нет
		hasGirlSubscription: {
			type: Boolean,
			default: false
		},
		consumerHasGirlSubscription: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		consumerProfileLink() {
			const routeName = this.$route.name
			if (routeName === 'author-reactions-id' || routeName === 'consumer-reactions-id') {
				return { name: 'consumer-profile-id', params: { id: this.authorId } }
			}
			return { name: 'consumer-profile', params: { id: this.authorId || 0, nickname: this.nickname } }
		},
		showAvatar() {
			return this.authorId && this.avatar
		}
	},
	methods: {
		onClickNickname() {
			if (this.onClickNicknameFunction) {
				this.onClickNicknameFunction(this.nickname)
				return
			}
			this.$router.push(this.consumerProfileLink)
		}
	}
}
</script>

<style lang="scss">
.comment {
	&-avatar {
		margin-right: 10px;
	}
}
</style>
