<template>
	<div :class="['fixed-top', { invisible: !headerVisible, animate: headerAnimate }]">
		<div class="header flex row space container">
			<slot>
				<button type="button" @click="$router.back()" class="back" />
			</slot>
			<div v-if="title" class="title">{{ title }}</div>
			<like-balance v-if="isConsumer" />
			<money-balance v-if="isGirl && show_lk_button" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useGirlProfileStore } from '@/stores/girlProfile'
import LikeBalance from '@/components/LikeBalance.vue'
import MoneyBalance from './MoneyBalance.vue'

export default {
	name: 'HeaderComponent',
	components: { LikeBalance, MoneyBalance },
	data() {
		return {
			headerVisible: true,
			headerAnimate: false
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	},
	methods: {
		handleScroll() {
			const currentScrollPosition = window.scrollY
			const setHeaderAttributes = (val) => {
				this.headerAnimate = val
				this.headerVisible = val
			}
			if (window.scrollY > 48) {
				setHeaderAttributes(false)
				if (currentScrollPosition < this.scrollPosition) {
					setHeaderAttributes(true)
				} else {
					setHeaderAttributes(false)
				}
			} else {
				setHeaderAttributes(true)
			}
			this.scrollPosition = window.scrollY
		}
	},
	computed: {
		...mapState(useGirlProfileStore, ['show_lk_button']),
		title() {
			return this.$route.meta.title
		},
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll)
	}
}
</script>
<style scoped>
.fixed-top.invisible {
	animation: hide 0.4s forwards;
}
.fixed-top.animate {
	transform: translateY(-150%);
	animation: show 0.4s forwards;
}
@keyframes show {
	0% {
		transform: translateY(-150%);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes hide {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-150%);
	}
}
</style>
