<script lang="jsx">
import isMobile from 'ismobilejs'
import { createButtonTypes } from '@/constants'

export default {
	name: 'CreateButton',
	functional: true,
	props: {
		type: {
			type: String,
			default: () => 'plus'
		},
		percentage: {
			type: Number,
			default: () => 0
		}
	},
	render(h, { props, listeners }) {
		const progressLength = () => {
			if (!props.percentage) {
				return 160
			}
			if (props.percentage >= 100) {
				return 0
			}
			return 160 - Math.ceil((160 / 100) * props.percentage)
		}
		const plusAligning = () =>
			isMobile().apple.device && props.type === createButtonTypes.default ? 'baseline' : 'center'
		const buttonClasses = ['btn-create', `btn-create_${createButtonTypes[props.type]}`]
		return (
			<button type="button" class={buttonClasses} onclick={() => listeners.openCreatePostDialog()}>
				<span class="btn-create-content" style={{ alignItems: plusAligning() }}>
					{props.type === createButtonTypes.default && <span class="btn-create-plus">+</span>}
					{props.type === createButtonTypes.loading && <span class="btn-create-progress">{props.percentage}%</span>}
					{props.type === createButtonTypes.ready && <span class="i-arrow-left btn-create-arrow" />}
				</span>
				<div class="blick" />
				<svg class="progress-bar">
					<linearGradient id="myGradient" gradientTransform="rotate(120)">
						<stop offset="30%" stop-color="#ff285c" />
						<stop offset="60%" stop-color="#cd1b7e" />
					</linearGradient>
					<circle
						style={`stroke-dashoffset: ${progressLength()}`}
						cx="50%"
						cy="50%"
						r="25.5"
						stroke="url('#myGradient')"
					/>
				</svg>
			</button>
		)
	}
}
</script>

<style lang="scss">
.btn-create {
	width: 56px;
	height: 56px;
	margin: 0 20px;
	border-radius: 50%;
	position: relative;
	border: none;
	cursor: pointer;
	background: -webkit-radial-gradient(50% 120%, ellipse cover, #ed326a, #fff 80%, #ed326a 100%);
	background: radial-gradient(circle at 50% 120%, #cd1b7e, #da2579 10%, #ff275b 80%, #ff285c 100%);
	& .blick {
		content: '';
		position: absolute;
		top: 9%;
		left: 12%;
		width: 65%;
		height: 53%;
		border-radius: 50%;
		background: radial-gradient(circle at 47% 0px, #ffffff, rgba(255, 255, 255, 0) 57%);
		filter: blur(0px);
		z-index: 200;
		transform: rotate(-23deg);
		filter: blur(2px);
		&::before {
			content: '';
			width: 100%;
			height: 119%;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 17%;
			background: radial-gradient(#ff275b, #ff235b);
			filter: blur(2px);
		}
	}
	&_loading {
		background: radial-gradient(circle at 50% 120%, #777680, #7f8088 10%, #7f8088 80%, #999aa4 100%);
		& .blick::before {
			background: radial-gradient(#999aa4, #777680);
		}
	}
	& .progress-bar {
		visibility: hidden;
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		top: 0;
		stroke: #ff275b;
		stroke-width: 5;
		z-index: 100;
		width: 100%;
		height: 100%;
	}
	&_loading .progress-bar {
		visibility: visible;
	}
}
.btn-create-content {
	z-index: 300;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	color: #fff;
}
.btn-create-plus {
	font-size: 44px;
}
.btn-create-progress {
	font-weight: 600;
	font-size: 12px;
}
.btn-create-arrow {
	transform: rotate(180deg);
	font-size: 24px;
}
circle {
	fill: transparent;
	transform-origin: center;
	transform: rotate(-90deg);
	stroke-width: 5px;
	stroke-dasharray: 160;
	stroke-dashoffset: 160;
	animation: clock-animation 10s linear infinite;
}
</style>
