import { http } from '@/http/index'

async function getMessages({ threadId = null, nextPageRoute = null }) {
	if (threadId) {
		return http.get(`/messenger/threads/${threadId}/messages`).then((response) => response.data)
	}
	if (nextPageRoute) {
		return http.get(nextPageRoute).then((response) => response.data)
	}
	return null
}

export { getMessages }
