import { defineStore } from 'pinia'

export const useCommonStore = defineStore('common', {
	state: () => ({
		isPowerSaveMode: false,
		deferredPrompt: null,
		tenant: import.meta.env.VITE_TENANT
	}),
	actions: {
		setDeferredPrompt(e) {
			this.deferredPrompt = e
		},
		removeDeferredPrompt() {
			this.deferredPrompt = null
		}
	}
})
