import Vue from 'vue'
import { defineStore } from 'pinia'
import { useCommonStore } from '@/stores/common'
import { detectSafari, detectIOS } from '@/helpers/detectPlatform'

export const useModalStore = defineStore('modal', {
	state: () => ({
		appLoaderVisibility: false,
		showVerificationDialog: false,
		balanceNotification: false,
		networkErrorNotification: false,
		showVerificationNeedsDialog: false,
		showPWADialog: false,
		showLinkGreetingsDialog: false,
		showVerificationStatusDialog: false,
		pwaText: '',
		commonStore: useCommonStore()
	}),
	actions: {
		showAppLoader() {
			this.appLoaderVisibility = true
		},
		hideAppLoader() {
			this.appLoaderVisibility = false
		},
		openVerificationDialog() {
			this.showVerificationDialog = true
		},
		closeVerificationDialog() {
			this.showVerificationDialog = false
		},
		showBalanceNotification() {
			this.balanceNotification = true
		},
		closeBalanceNotification() {
			this.balanceNotification = false
		},
		showNetworkError() {
			this.networkErrorNotification = true
			setTimeout(this.hideNetworkError, 3000)
		},
		hideNetworkError() {
			this.networkErrorNotification = false
		},
		openVerificationNeedsDialog() {
			this.showVerificationNeedsDialog = true
		},
		closeVerificationNeedsDialog() {
			this.showVerificationNeedsDialog = false
		},
		openPWADialog() {
			this.pwaText = this.getPwaText()
			if (this.pwaText && localStorage.getItem('pwaAsk') === null) {
				localStorage.setItem('pwaAsk', true)
				Vue.$metrika.reachGoal('pwa-ask')
				this.showPWADialog = true
			}
		},
		getPwaText() {
			if (detectIOS() && detectSafari() && !this.commonStore.deferredPrompt) {
				return 'safari'
			}
			if (detectIOS() && !this.commonStore.deferredPrompt) {
				return 'ios'
			}
			if (this.commonStore.deferredPrompt) {
				return 'canPWAInstall'
			}
			return ''
		},
		closePWADialog(installationStarted) {
			if (!installationStarted) {
				Vue.$metrika.reachGoal('pwa-no')
			}
			this.commonStore.removeDeferredPrompt()
			this.showPWADialog = false
		},
		openLinkGreetingsDialog() {
			this.showLinkGreetingsDialog = true
		},
		closeLinkGreetingsDialog() {
			this.showLinkGreetingsDialog = false
		},
		openVerificationStatusDialog() {
			this.showVerificationStatusDialog = true
		},
		closeVerificationStatusDialog() {
			this.showVerificationStatusDialog = false
		}
	}
})
