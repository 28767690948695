export const createButtonTypes = {
	default: 'default',
	loading: 'loading',
	ready: 'ready'
}

export const complaintToModeratorText = 'Модераторы рассмотрят ваше обращение в течение 24 часов'

export const subscriptionsParams = {
	likesForSubscription: 3,
	defaultSubscriptionsCount: 1
}

export const menuList = {
	default: [
		'author-schedule',
		'becomeAnConsumer',
		'becomeAnAuthor',
		'consumer-subscriptions',
		'receipts',
		'eula',
		'terms-of-service',
		'privacy-policy',
		'exit'
	],
	tele2ru: [
		'author-schedule',
		'becomeAnConsumer',
		'consumer-subscriptions',
		'rules',
		'eula',
		'terms-of-service',
		'privacy-policy'
	]
}
