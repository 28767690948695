<template>
	<div
		:class="[
			weekMode ? 'week-mode' : '',
			weekNumber ? `on-week-${weekNumber}` : '',
			verticalMode ? 'vertical-mode' : '',
			visibility ? '' : 'hidden'
		]"
	>
		<vc-date-picker
			ref="calendar"
			title-position="left"
			is-expanded
			is-dark
			:disable-page-swipe="true"
			locale="ru"
			:masks="{ weekdays: 'WW', title: 'MMMM' }"
			trim-weeks
			:attributes="attributes"
			:rows="rows"
			:step="step"
			:min-date="minDate"
			:max-date="maxDate"
			v-model="date"
			@dayclick="onDayClick"
		>
			<template v-if="createNewPost && weekMode" #header>
				<button class="month-button" type="button" @click="exitWeekMode">
					<svg-icon name="arrow-back" />
					<span>{{ currentMonth }}</span>
				</button>
			</template>
		</vc-date-picker>
	</div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'Calendar',
	components: { SvgIcon },
	props: {
		attributes: { type: Array, default: () => [] },
		rows: {
			type: Number,
			default: 1
		},
		step: {
			type: Number,
			default: 1
		},
		weekMode: {
			type: Boolean,
			default: false
		},
		selectedDay: { type: String, default: '' },
		weekNumber: { type: String, default: '' },
		minDate: {
			type: String,
			default: ''
		},
		maxDate: {
			type: String,
			default: ''
		},
		currentDay: {
			type: Date,
			default: () => null
		},
		createNewPost: {
			type: Boolean,
			default: false
		},
		verticalMode: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			date: null,
			prevDay: null,
			visibility: true
		}
	},
	computed: {
		currentMonth() {
			if (this.prevDay) {
				return new Intl.DateTimeFormat('ru-RU', {
					month: 'long'
				}).format(new Date(this.prevDay))
			}
			return ''
		}
	},
	methods: {
		onDayClick(day) {
			this.$emit('day-click', day)
		},
		exitWeekMode() {
			this.$emit('exit-week-mode')
		},
		removeDate() {
			this.date = null
		}
	},
	watch: {
		currentDay() {
			if (this.currentDay) {
				this.date = this.currentDay
				this.prevDay = this.currentDay
				this.$refs.calendar.move(this.date)
			}
		}
	},
	mounted() {
		if (this.selectedDay) {
			this.$refs.calendar.move(this.selectedDay)
		}
		if (this.verticalMode) {
			this.visibility = false
			const centerMonth = this.$refs.calendar.$children[0].$children[1].$children[3].$el
			const position = centerMonth.getBoundingClientRect()
			this.$nextTick(() => window.scrollTo(position.left, position.top + window.scrollY - 48))
			setTimeout(() => {
				this.visibility = true
			}, 500)
		}
	}
}
</script>

<style lang="scss">
.vc-container.vc-is-dark {
	color: #ffffff !important;
	background-color: var(--primary) !important;
	border-color: transparent !important;
}
.vc-header {
	padding: 10px 0 !important;
}
.vc-weeks {
	padding: 0 !important;
}
.vc-weekday {
	font-size: 11px !important;
	color: var(--semi-transparent) !important;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(67, 72, 86, 0.5);
	margin-bottom: 10px;
}
.vc-title {
	text-transform: capitalize;
}

.vc-day {
	height: 36px !important;
}

.vc-day-box-center-center {
	width: 100%;
	height: 36px;
	margin: 0 auto 3px auto;
	align-items: flex-start !important;
}

.week-mode .vc-day {
	min-height: 0 !important;
	height: 0 !important;
	margin-bottom: 0 !important;
}

.vc-is-dark .vc-day-content:focus,
.vc-is-dark .vc-day-content:hover {
	height: 36px !important;
}

.vc-is-dark .vc-day-content:focus {
	background: transparent !important;
}
.vc-is-dark .vc-arrow:focus,
.vc-is-dark .vc-arrow:hover {
	background-color: transparent !important;
}

.vc-day-content {
	font-weight: normal !important;
	align-items: flex-start !important;
	font-size: 16px !important;
}
.vc-highlight {
	width: 36px !important;
	height: 36px !important;
	border-radius: 6px !important;
	opacity: 1 !important;
	background-color: transparent !important;
}
.vc-day .vc-highlight-pick {
	background-color: var(--red) !important;
}
.vc-day.is-today .vc-highlight-today {
	border-radius: 6px !important;
	background-color: transparent !important;
	border: 1px solid var(--semi-transparent) !important;
}

.vc-dot {
	margin-bottom: 6px;
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.vc-bar {
	display: none;
}
.vc-bars {
	position: relative;
	width: 36px !important;
	height: 100%;
}
.vc-bars:before {
	content: '';
	display: block !important;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	border-style: solid;
	border-width: 0 8px 8px 0;
	border-color: transparent #ed3232 transparent transparent;
}
.vc-bars:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	border-style: solid;
	border-width: 0 10px 10px 0;
	border-color: transparent var(--primary) transparent transparent;
}

.vc-is-dark .vc-day-content:hover {
	width: 36px;
	height: 36px;
	border-radius: 6px;
	background-color: var(--red) !important;
	align-items: flex-start;
}
.vc-day-content.is-disabled:focus,
.vc-day-content.is-disabled:hover {
	background-color: transparent !important;
}

.vc-is-dark .vc-day-content.is-disabled {
	color: rgba(255, 255, 255, 0.5) !important;
}
.vc-is-dark .vc-day-content:focus + .vc-day-layer .vc-dot,
.vc-is-dark .vc-day-content:hover + .vc-day-layer .vc-dot {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.vc-arrows-container {
	padding: 8px 0 !important;
}
.vc-arrow.is-right {
	margin-left: 25px;
}
.week-mode .vc-header,
.week-mode .vc-arrows-container,
.week-mode .vc-day {
	visibility: hidden !important;
	height: 0;
}
.week-mode.on-week-1 .week-1.in-month,
.week-mode.on-week-2 .week-2.in-month,
.week-mode.on-week-3 .week-3.in-month,
.week-mode.on-week-4 .week-4.in-month,
.week-mode.on-week-5 .week-5.in-month {
	visibility: visible !important;
	height: 36px !important;
	min-height: 36px !important;
}
.week-mode .is-not-in-month {
	visibility: hidden !important;
	height: 0 !important;
	min-height: 0 !important;
}
.week-mode.on-week-1 .in-prev-month.is-not-in-month *,
.week-mode.on-week-5 .in-prev-month.is-not-in-month *,
.week-mode.on-week-5 .in-next-month.is-not-in-month * {
	opacity: 1 !important;
	pointer-events: auto !important;
}

.week-mode.on-week-1 .in-prev-month.is-not-in-month .vc-day-layer,
.week-mode.on-week-5 .in-prev-month.is-not-in-month .vc-day-layer,
.week-mode.on-week-5 .in-next-month.is-not-in-month .vc-day-layer {
	pointer-events: none !important;
}

.week-mode.on-week-1 .in-prev-month,
.week-mode.on-week-5 .in-next-month {
	visibility: visible !important;
	height: 36px !important;
	min-height: 36px !important;
}
.new-post-calendar .vc-highlight {
	background-color: var(--red) !important;
}

.new-post-calendar.week-mode .vc-header {
	visibility: visible !important;
}

.month-button {
	display: flex;
	align-items: center;
	border: none;
	background: none;
	color: white;
	font-weight: 600;
	line-height: 28px;
	font-size: 18px;
	margin-top: 20px;
	text-transform: Capitalize;
}

.month-button .svg-icon {
	color: #727281;
}
.vertical-mode .vc-arrows-container {
	display: none !important;
}
.vertical-mode.hidden {
	visibility: hidden;
}
</style>
