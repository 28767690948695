<template>
	<div class="time-picker-container">
		<scrollPicker :options="hoursArr" v-model="hours" @input="hoursUpdate" />
		<scrollPicker :options="minutesArr" v-model="minutes" @input="hoursUpdate" />
	</div>
</template>

<script>
import { ScrollPicker } from 'vue-scroll-picker'

import 'vue-scroll-picker/dist/style.css'

export default {
	name: 'TextareaComponent',
	inheritAttrs: false,
	components: { ScrollPicker },
	props: {
		value: { type: String, default: '' },
		MinDateHour: { type: Number, default: 0 }
	},
	data() {
		return {
			minutesArr: Array.from({ length: 12 }, (value, index) => String(index * 5).padStart(2, '0')),
			hoursArr: Array.from({ length: 24 }, (value, index) => String(index).padStart(2, '0')),
			hours: '',
			minutes: '',
			currentTime: ''
		}
	},
	mounted() {
		this.hours = this.getDefaultHour()
		this.minutes = this.getDefaultMinute()
		this.hoursArr = this.hoursArr.slice(this.MinDateHour)
		this.currentTime = `${this.hours}:${this.minutes}`
		this.$emit('update', this.currentTime)
	},
	methods: {
		getDefaultHour() {
			return this.hoursArr[0]
		},
		getDefaultMinute() {
			return this.minutesArr[0]
		},
		hoursUpdate() {
			this.currentTime = `${this.hours}:${this.minutes}`
			this.$emit('update', this.currentTime)
		}
	}
}
</script>

<style>
.time-picker-container {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	margin-top: 16px;
}
.time-picker-container:before {
	content: '';
	position: absolute;
	z-index: -1;
	left: 0;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	height: 36px;
	border-radius: 7px;
	background: rgba(44, 45, 55, 0.5);
}
.time-picker-container .vue-scroll-picker {
	flex: 1;
}
.vue-scroll-picker {
	height: 100px;
}
.vue-scroll-picker-layer .top {
	background: linear-gradient(180deg, rgba(25, 28, 34, 0.9), rgba(25, 28, 34, 0.4));
	border: 0;
}
.vue-scroll-picker-layer .bottom {
	background: linear-gradient(0deg, rgba(25, 28, 34, 0.9), rgba(25, 28, 34, 0.4));
	border: 0;
}
.vue-scroll-picker-layer .top,
.vue-scroll-picker-layer .bottom {
	height: calc(50% - 18px);
}
.vue-scroll-picker-item {
	display: flex;
	align-items: center;
	height: 36px;
	font-size: 22px;
	line-height: 1;
	justify-content: flex-end;
	padding-right: 20px;
}
.vue-scroll-picker + .vue-scroll-picker .vue-scroll-picker-item {
	padding: 0 0 0 20px;
	justify-content: flex-start;
}
</style>
