import Vue from 'vue'
import Pusher from 'pusher-js/with-encryption'
import { useMessengerStore } from '@/stores/messenger'

function connect() {
	Pusher.logToConsole = true
	window.pusher = new Pusher(import.meta.env.VITE_PUSHER_TOKEN, {
		cluster: 'eu',
		authEndpoint: import.meta.env.VITE_BROADCASTING_AUTH,
		auth: {
			headers: {
				Authorization: `Bearer ${Vue.auth.token()}`
			}
		}
	})
}

function getChannelName(id) {
	if (Vue.auth.check('girl')) {
		return `private-messenger.girl.${id}`
	}
	return `private-messenger.consumer.${id}`
}

function subscribe(id) {
	const channel = window.pusher.subscribe(getChannelName(id))
	channel.bind('new.message', (data) => {
		useMessengerStore().newMessage(data, id)
	})

	channel.bind('message.archived', (data) => {
		useMessengerStore().removeRoomMessage(data)
	})

	channel.bind('thread.archived', (data) => {
		useMessengerStore().removeThread(data)
	})

	channel.bind('new.thread', () => {
		useMessengerStore().newThread()
	})
	channel.bind('thread.read', (data) => {
		useMessengerStore().threadRead(data)
	})
}

function subscribeThread(id) {
	const channel = window.pusher.subscribe(`presence-messenger.thread.${id}`)
	channel.bind('message.edited', (data) => {
		useMessengerStore().editMessage(data)
	})
	channel.bind('thread.avatar', (data) => {
		useMessengerStore().threadAvatar(data)
	})
	channel.bind('reaction.added', (data) => {
		useMessengerStore().updateReactions(data)
	})
	channel.bind('reaction.removed', (data) => {
		useMessengerStore().updateReactions(data)
	})
}

function checkConnection() {
	return window.pusher
}

export default { connect, subscribe, subscribeThread, checkConnection }
