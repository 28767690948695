<template>
	<div class="consumer-tutorial tutorial">
		<div ref="swiper" class="consumer-tutorial-slider swiper">
			<div class="consumer-tutorial-close" @click="closeTutorial">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.99974 5.58672L11.9497 0.636719L13.3637 2.05072L8.41374 7.00072L13.3637 11.9507L11.9497 13.3647L6.99974 8.41472L2.04974 13.3647L0.635742 11.9507L5.58574 7.00072L0.635742 2.05072L2.04974 0.636719L6.99974 5.58672Z"
						fill="#727281"
					/>
				</svg>
			</div>

			<div class="swiper-wrapper">
				<div class="swiper-slide consumer-tutorial-slide">
					<div class="consumer-tutorial-text">
						<strong>MyMood</strong> - идеальное место, где вы сможете удовлетворить все самые смелые и желанные фантазии
					</div>
					<div class="consumer-tutorial-img-wrap">
						<div class="consumer-tutorial-img-bg" />
						<img
							:src="require('@/assets/images/consumer-tutorial/img-1.png')"
							alt="img-1"
							class="consumer-tutorial-img img-1"
						/>
					</div>
				</div>
				<div class="swiper-slide consumer-tutorial-slide">
					<div class="consumer-tutorial-text">
						Подписывайтесь на авторов и получайте от них свежий и эксклюзивный контент
					</div>
					<div class="consumer-tutorial-img-wrap">
						<img
							:src="require('@/assets/images/consumer-tutorial/img-2.png')"
							alt="img-2"
							class="consumer-tutorial-img img-2"
						/>
					</div>
				</div>
				<div class="swiper-slide consumer-tutorial-slide">
					<div class="consumer-tutorial-text">
						Оценивайте любимые посты лайками, ведь самых активных и преданных фанатов ждут особые бонусы
					</div>
					<div class="consumer-tutorial-img-wrap">
						<img
							:src="require('@/assets/images/consumer-tutorial/img-3.png')"
							alt="img-3"
							class="consumer-tutorial-img img-2"
						/>
					</div>
				</div>
				<div v-if="tenant !== 'tele2ru'" class="swiper-slide consumer-tutorial-slide">
					<div class="consumer-tutorial-text">
						Общайтесь в личных чатах и наслаждайтесь каждым мгновением вместе с <strong>MyMood</strong>
					</div>
					<div class="consumer-tutorial-img-wrap">
						<div class="consumer-tutorial-img-bg" />
						<img
							:src="require('@/assets/images/consumer-tutorial/img-4.png')"
							alt="img-4"
							class="consumer-tutorial-img img-1"
						/>
					</div>
				</div>
				<div class="swiper-slide consumer-tutorial-slide">
					<div class="consumer-tutorial-img-wrap">
						<div class="consumer-tutorial-img-bg" />
						<img
							:src="require('@/assets/images/consumer-tutorial/img-5.png')"
							alt="img-5"
							class="consumer-tutorial-img img-1"
						/>
					</div>
					<div class="consumer-tutorial-btn" @click="endTutorial">Начать</div>
				</div>
			</div>
			<div class="swiper-controls">
				<div class="swiper-pagination" />
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, Navigation, Pagination } from 'swiper'
import { mapState } from 'pinia'
import { useCommonStore } from '@/stores/common'

export default {
	name: 'ConsumerTutorialComponent',
	data() {
		return {
			currentSlide: 1
		}
	},
	mounted() {
		document.body.classList.add('disabled-scroll')
		const _this = this
		const swiper = new Swiper(this.$refs.swiper, {
			modules: [Navigation, Pagination],
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			on: {
				init() {
					_this.$emit('shown')
				}
			}
		})
		swiper.on('activeIndexChange', () => {
			this.currentSlide = swiper.activeIndex + 1
		})
	},
	computed: {
		...mapState(useCommonStore, ['tenant'])
	},
	methods: {
		closeTutorial() {
			this.$emit('close', this.currentSlide, false)
		},
		endTutorial() {
			this.$emit('end')
		}
	},
	beforeDestroy() {
		document.body.classList.remove('disabled-scroll')
	}
}
</script>

<style lang="scss">
.tutorial {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);

	&-image {
		width: 100%;
		height: 100vh;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}

.consumer-tutorial {
	&-slider {
		position: absolute;
		left: 50%;
		width: 100%;
		max-width: 520px;
		transform: translateX(-50%);
		top: 15%;
		bottom: 0;
		background-color: #300022;
		border-radius: 16px 16px 0 0;
		color: #ffffff;
	}

	&-close {
		position: absolute;
		top: 16px;
		right: 20px;
		z-index: 10;
		cursor: pointer;

		path {
			transition: fill 0.2s ease-in-out;
			fill: #727281;
		}

		&:hover,
		&:active {
			path {
				fill: #ffffff;
			}
		}
	}

	&-text {
		max-width: 335px;
		text-align: center;
		font-size: 14px;
		line-height: 143%;
		min-height: 70px;
		margin: 0 auto;
		position: relative;
		z-index: 2;
	}

	&-img-wrap {
		position: relative;
		z-index: 1;
	}

	&-img {
		object-fit: contain;
		display: block;
		margin: 0 auto;
		max-height: 45vh;

		&.img-1 {
			max-width: 340px;
			max-height: 54vh;
		}
	}
	&-img-bg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 504px;
		height: 640px;
		background: url('@/assets/images/consumer-tutorial/bg.png') 50% 50% no-repeat;
		background-size: contain;
		z-index: -1;
	}

	&-slide {
		padding: 55px 20px;
		position: relative;
		overflow: hidden;
	}

	&-btn {
		display: flex;
		width: 100%;
		max-width: 220px;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		background-color: #ed326a;
		height: 52px;
		border-radius: 12px;
		margin: 10px auto 0;
		font-weight: 700;
		position: relative;
		z-index: 10;
	}
}

.swiper {
	&-controls {
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		height: 20px;
		z-index: 99999;
	}
	&-button-next,
	&-button-prev {
		position: absolute;
		top: 0;
		height: 20px;
		width: 20px;
		&:before {
			content: '';
			position: absolute;
			border-top: 3px solid #fff;
			border-right: 3px solid #fff;
			width: 12px;
			height: 12px;
			top: 50%;
			left: 50%;
		}
	}
	&-button-prev {
		left: 0;
		&:before {
			transform: translate3d(-50%, -75%, 0px) rotate(225deg);
		}
	}
	&-button-next {
		right: 0;
		&:before {
			transform: translate3d(-50%, -75%, 0px) rotate(45deg);
		}
	}
	&-pagination {
		margin: 0 40px;
		display: flex;
		flex-flow: row nowrap;
		column-gap: 15px;
		z-index: 99999;
		align-items: center;
		height: 12px;
		&-bullet {
			width: 8px;
			height: 8px;
			border-radius: 20px;
			background-color: rgba(255, 255, 255, 0.5);
			transition: all 0.3s linear;
			&-active {
				background-color: #fff;
				width: 12px;
				height: 12px;
			}
		}
	}
}
</style>
