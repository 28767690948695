<template>
	<div class="post-video-component post-media-data">
		<input-media
			ref="mediaInputVideo"
			@loadend="onLoadEnd"
			:rules="{ minDimensions: [500, 217] }"
			format-file="video/*"
		/>
		<dialog-component v-show="showCoverModal" class="edit-photo" @close="HideCoverModal">
			<template #header-title>Изменить обложку</template>
			<p class="create-post-choose-title">Выбери превью для видео</p>
			<div class="relative">
				<div ref="swiper" class="create-post-preview swiper">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="preview in previews" :key="`pr${preview.preview_id}`">
							<img :src="preview.url" class="w-full placeholder-bg" alt="" />
						</div>
					</div>
				</div>
				<div class="preview-list">
					<div
						:class="['preview-img-wrap', { 'preview-img-wrap-active': index === currentSlide }]"
						v-for="(preview, index) in previews"
						:key="`pr${preview.preview_id}`"
						@click="changeCurrentSlide(index)"
					>
						<img :src="preview.url" :class="['preview-img']" alt="" />
					</div>
				</div>
			</div>
			<template #dialog-footer>
				<ButtonComponent @click="onChoose" color="primary"> Выбрать </ButtonComponent>
			</template>
		</dialog-component>

		<template v-if="preview_url || videoAsLink">
			<div
				:class="['create-post-preview relative fix-scroll', { 'create-post-preview-horizontal': itsHorizontalVideo }]"
			>
				<template v-if="videoAsLink">
					<button type="button" @click="changePlayVideo" class="video-controll">
						<span v-show="videoIsPause" class="video-controll--play video-controll-icon" />
						<span v-show="!videoIsPause" class="video-controll--pause video-controll-icon" />
					</button>
					<video
						ref="videoBlock"
						@loadedmetadata="checkVideoRatio"
						:src="videoAsLink"
						class="w-full"
						playsinline
						muted
						loop
					/>
				</template>
				<template v-if="preview_url">
					<upload-image-preview :src="preview_url" />
					<video-processing-notice> Видео обрабатывается, но пост уже можно публиковать </video-processing-notice>
				</template>

				<div v-if="hasPreviews" class="create-post-image-controls">
					<button type="button" class="create-post-control button-flex" @click="onEdit">
						<span class="icon-cover" />
						<span>Изменить обложку</span>
					</button>
				</div>
				<div v-else class="create-post-image-controls">
					<button type="button" class="create-post-control button-flex" @click="openMediaChoose">
						<i class="i-pen" />
						<span>Заменить видео</span>
					</button>
				</div>
			</div>
			<!-- <ButtonComponent :loading="isSending" @click="$emit('submit')">Опубликовать</ButtonComponent> -->
		</template>
	</div>
</template>

<script>
import { Swiper } from 'swiper'
import { mapActions, mapState } from 'pinia'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useCreatePostStore } from '@/stores/createPost'
import UploadImagePreview from '@/components/UploadImagePreview.vue'
import VideoProcessingNotice from '@/components/VideoProcessingNotice.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import InputMedia from '@/components/create-post/InputMedia.vue'

export default {
	name: 'CreateVideoMedia',
	components: { VideoProcessingNotice, UploadImagePreview, ButtonComponent, DialogComponent, InputMedia },
	// props: {
	// 	isSending: Boolean
	// },
	data() {
		return {
			currentSlide: 0,
			showCoverModal: false,
			videoIsPause: true,
			itsHorizontalVideo: false
		}
	},
	updated() {
		if (this.preview_url) {
			const img = new Image()
			img.onload = () => {
				this.itsHorizontalVideo = img.height > img.width
			}
			img.src = this.preview_url
		}
	},
	computed: {
		...mapState(useCreatePostStore, ['isCreating', 'previews', 'preview_url', 'videoAsLink', 'post']),
		hasPreviews() {
			if (this.previews) {
				return this.previews.length !== 0
			}
			return false
		},
		totalPreviews() {
			return this.previews.length
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['choosePreview', 'resetVideo']),
		...mapActions(useVideoUploadStore, ['deleteVideo', 'initUploader', 'addFile']),
		changePlayVideo() {
			if (this.videoIsPause) {
				this.$refs.videoBlock.play()
			} else {
				this.$refs.videoBlock.pause()
			}
			this.videoIsPause = !this.videoIsPause
		},
		checkVideoRatio() {
			if (this.$refs.videoBlock) {
				this.itsHorizontalVideo = this.$refs.videoBlock.videoHeight > this.$refs.videoBlock.videoWidth
			}
		},
		openMediaChoose() {
			this.$refs.mediaInputVideo.$refs.media.value = ''
			this.$refs.mediaInputVideo.$refs.media.click()
		},
		onLoadEnd(fileInfo) {
			this.initUploader(this.$refs.browse)
			this.addFile(fileInfo.file)
		},
		changeCurrentSlide(index) {
			this.currentSlide = index
			this.$refs.swiper.swiper.slideTo(index)
		},
		onChoose() {
			this.showCoverModal = false
			this.choosePreview(this.currentSlide)
		},
		onReset() {
			if (this.isCreating) {
				this.deleteVideo(this.post.video_id)
			}
			this.currentSlide = 0
			this.resetVideo()
		},
		onEdit() {
			this.showCoverModal = true
			const swiper = new Swiper(this.$refs.swiper)
			swiper.on('slideChange', (e) => {
				this.currentSlide = e.activeIndex
			})
		},
		HideCoverModal() {
			this.showCoverModal = false
		}
	},
	mounted() {
		if (this.hasPreviews) {
			this.choosePreview(0)
		}
	}
}
</script>

<style>
.create-post-choose-title {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 15px;
	text-align: center;
	color: #bebec9;
}

.create-post-preview.fix-scroll::after {
	/* Фикс скролла видео на iOS 17.1.1.-17.1.2. */
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.icon-cover::before {
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	background-image: url('@/assets/images/icons/img.svg');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 2px;
}

.button-flex {
	align-items: center;
}

.preview-list {
	display: flex;
	justify-content: center;
}

.preview-img-wrap {
	position: relative;
	width: 46px;
	height: 61px;
	margin-bottom: 40px;
	margin-top: 40px;
	cursor: pointer;
}

.preview-img-wrap::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 0.4s ease-in-out;
	background-color: rgba(0, 0, 0, 0.5);
}

.preview-img {
	width: 100%;
	height: 100%;
	width: 46px;
	transition:
		transform 0.6 ease-in-out,
		border 0.6 ease-in-out;
}

.preview-img-wrap:not(:last-child) {
	margin-right: 10px;
}

.preview-img-wrap-active::before {
	opacity: 0;
}

.preview-img-wrap-active .preview-img {
	transform: scale(1.2);
	border: 1.5px solid white;
	border-radius: 8px;
}

.video-controll {
	width: 50px;
	height: 50px;
	border-radius: 25px;
	background-color: rgba(25, 28, 34, 0.5);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 10;
	border: none;
}

.video-controll-icon {
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
}

.video-controll--play {
	background-image: url('@/assets/images/icons/play.svg');
}

.video-controll--pause {
	background-image: url('@/assets/images/icons/pause.svg');
}

.create-post-preview video,
.create-post-preview img {
	border-radius: 12px;
}
.create-post-preview-horizontal {
	padding-left: 50px;
	padding-right: 50px;
}
</style>
