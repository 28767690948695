<template>
	<validation-provider
		tag="div"
		:name="id"
		ref="provider"
		class="input-image"
		:rules="{
			required: true,
			imageExt: ['png', 'jpeg', 'jpg', 'mp4', 'mov'],
			aspectRatio: true,
			size: maxImageSize,
			...rules
		}"
	>
		<input hidden ref="media" id="media" type="file" :accept="formatFile" @change="onChange($event)" />
	</validation-provider>
</template>

<script>
import { mapWritableState, mapState, mapActions } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'
import { ValidationProvider } from 'vee-validate'

const minCropAspectRatio = 0.75
const minCroppedWidth = 500

const validationErrorDescriptions = {
	minDimensions: 'Выбери другое фото.',
	size: 'Попробуй уменьшить размер в каком-нибудь приложением.',
	imageExt: 'Переделай в один из этих форматов в каком-нибудь приложении.'
}

export default {
	name: 'InputMedia',
	components: { ValidationProvider },
	props: {
		id: { type: String, default: '' },
		rules: { type: Object, default: () => {} },
		formatFile: { type: String, default: 'video/*, image/*' }
	},
	computed: {
		...mapWritableState(useCreatePostStore, ['croppedBase64', 'fullBase64']),
		...mapState(useCreatePostStore, ['showValidationDialog', 'validationError']),
		maxImageSize() {
			return Number(import.meta.env.VITE_MAX_IMG_SIZE_IN_BYTES)
		},
		imageExt() {
			if (this.formatFile === 'image/*') {
				return ['png', 'jpeg', 'jpg']
			}
			if (this.formatFile === 'video/*') {
				return ['mp4', 'mov']
			}
			return ['png', 'jpeg', 'jpg', 'mp4', 'mov']
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['showValidationError']),
		async onChange(e) {
			const { files } = e.target
			if (files && files[0]) {
				this.$emit('loadstart')
				const res = await this.$refs.provider.validate(e)
				if (res.valid) {
					await this.getImageSrc(e)
					this.onInput(e)
					this.$emit('loadend', this.getFileInfo(files[0]))
				} else {
					this.$emit('loadend')
					this.onError(res)
				}
			}
		},
		getImageSrc(e) {
			return new Promise((resolve) => {
				const { files } = e.target
				if (files && files.length > 0) {
					const file = files[0]
					this.$emit('input', files)
					const reader = new FileReader()
					reader.onload = () => {
						this.$emit('attached', reader.result)
						resolve(reader.result)
					}
					reader.readAsDataURL(file)
				}
			})
		},
		onError(error) {
			const errorKey = Object.keys(error.failedRules)[0]
			this.showValidationError(error.failedRules[errorKey], validationErrorDescriptions[errorKey])
		},
		getFileInfo(file) {
			const fileType = file.type
			return {
				type: fileType.slice(0, fileType.indexOf('/')),
				loadSuccess: true,
				file
			}
		},
		onInput(event) {
			const { files } = event.target
			this.showSubmitButton = true
			this.isLoadingImage = true
			if (files && files[0]) {
				this.$emit('input', files[0])
				const reader = new FileReader()

				reader.onload = (e) => {
					this.fullBase64 = e.target.result
					const image = new Image()

					image.onload = () => {
						const canvas = document.createElement('canvas')
						const ctx = canvas.getContext('2d')
						const { width, height } = ctx.getImageData(0, 0, 1, 1)
						const aspectRatio = +(width / height).toFixed(2)
						canvas.width = 500
						canvas.height = 666
						const centerY = height / 2
						const offsetX = image.width * 0.1
						const croppedWidth = image.width - offsetX * 2
						const croppedHeight = croppedWidth / minCropAspectRatio
						const offsetY = centerY - croppedHeight / 2

						if (aspectRatio < 1) {
							this.minStencilWidth = width * 0.5
						} else {
							this.minStencilWidth = width * 0.3
						}
						this.minStencilHeight = this.minStencilWidth * 0.5

						if (aspectRatio < minCropAspectRatio) {
							ctx.drawImage(image, offsetX, offsetY, croppedWidth, croppedHeight, 0, 0, minCroppedWidth, canvas.height)
							this.croppedBase64 = canvas.toDataURL()
							this.sendCropEvent(croppedWidth, croppedHeight, offsetX, offsetY)
						} else {
							this.croppedBase64 = this.fullBase64
						}
						this.isLoadingImage = false
						canvas.remove()
					}

					image.src = this.fullBase64
				}
				reader.readAsDataURL(files[0])
			}
		}
	}
}
</script>
