<template functional>
	<img class="upload-image-preview" v-show="props.src" :src="props.src" alt="" ref="previewImg" />
</template>

<script>
export default {
	name: 'UploadImagePreview',
	props: {
		src: { type: String, default: '' }
	}
}
</script>

<style>
.upload-image-preview {
	width: 100%;
	height: auto;
	margin: auto;
	display: flex;
	border-radius: 12px;
}
</style>
