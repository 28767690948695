import { http } from '@/http/index'

async function getPost(id) {
	return http.get(`/posts/${id}`).then((response) => response.data.data)
}

async function sendComment(id, comment) {
	return http
		.post(`posts/${id}/comments`, {
			comment
		})
		.then((response) => response.data.data)
}

async function getPostComments(id) {
	return http.get(`/posts/${id}/comments`).then((response) => response.data)
}

async function getMorePostComments(url) {
	return http.get(url).then((response) => response.data)
}

async function getPosts() {
	return http.get(`/posts`).then((response) => response.data)
}

async function getPostsByGirlId(girlId) {
	return http.get(`/girls/${girlId}/posts`).then((response) => response.data)
}

async function getMorePosts(url) {
	return http.get(url).then((response) => response.data)
}

async function viewPost(id) {
	return http.post('/post/view', { post_id: id })
}

export {
	getPost,
	getPostComments,
	getPosts,
	getMorePosts,
	sendComment,
	getMorePostComments,
	viewPost,
	getPostsByGirlId
}
