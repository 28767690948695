import { getPostAudienceList } from '@/http/girl/createPost/postAudience'
import { createPostWithPhoto, createPostWithVideo } from '@/http/girl/createPost/createPost'
import { deleteVideo } from '@/http/girl/createPost/deleteVideo'
import { editPostWithPhoto, editPostWithVideo } from '@/http/girl/createPost/editPost'

export default {
	getPostAudienceList,
	createPostWithPhoto,
	createPostWithVideo,
	editPostWithPhoto,
	editPostWithVideo,
	deleteVideo
}
