import { defineStore } from 'pinia'
import girlSchedule from '@/http/girl/schedule'

export const useGirlSchedule = defineStore('girlSchedule', {
	state: () => ({
		schedulePosts: null,
		filteredSchedulePosts: null,
		selectedFilter: 'total',
		schedulesList: null,
		scheduleByDay: null,
		selectedDay: null,
		isLoading: false
	}),
	actions: {
		async getSchedulePosts() {
			try {
				this.isLoading = true
				if (this.schedulePosts === null) {
					this.schedulePosts = await girlSchedule.getSchedulePosts()
					this.filteredSchedulePosts = this.schedulePosts.data
				}
			} finally {
				this.isLoading = false
			}
		},
		resetSchedulePosts() {
			this.schedulePosts = null
		},
		filterSchedulePosts(val) {
			this.selectedFilter = val
			if (val === 'total') {
				this.filteredSchedulePosts = this.schedulePosts.data
			} else {
				this.filteredSchedulePosts = this.schedulePosts?.data.filter((post) => post.audience === val)
			}
		},
		async getSchedulesList() {
			try {
				const result = await girlSchedule.getSchedulesList()
				if (result) {
					this.schedulesList = result
				}
			} catch (error) {
				// eslint-disable-next-line
				console.trace(error)
				throw error
			}
		},
		async getScheduleByDay(day) {
			try {
				this.isLoading = true
				const result = await girlSchedule.getScheduleByDay(day)
				if (result) {
					this.scheduleByDay = result
				}
			} catch (error) {
				// eslint-disable-next-line
				console.trace(error)
				throw error
			} finally {
				this.isLoading = false
			}
		},
		setSelectedDay(day) {
			this.selectedDay = day
		}
	}
})
