<template>
	<span>
		<span class="word-break" v-html="isCollapsed ? textPreview : textFull" />
		<b v-if="isCollapsed" class="word-break_fade" @click="expand">{{ expandText ? 'Ещё' : '...' }}</b>
	</span>
</template>

<script>
export default {
	name: 'CollapsedText',
	props: {
		text: { type: String, default: '' },
		previewLettersCount: { type: Number, required: true },
		transformText: { type: [Function, null], default: null },
		expandText: { type: Boolean, default: true }
	},
	data() {
		return {
			isExpanded: false,
			textPreview: this.text ? this.text.slice(0, this.previewLettersCount) : '',
			textFull: this.text
		}
	},
	computed: {
		isCollapsed() {
			return this.isLongText && !this.isExpanded
		},
		isLongText() {
			if (this.text) return this.text.length > this.previewLettersCount
			return false
		}
	},
	methods: {
		expand() {
			if (this.expandText) {
				this.isExpanded = true
				this.$emit('expand')
				this.formatFullText()
			}
		},
		formatFullText() {
			if (this.transformText) {
				this.textFull = this.transformText(this.textFull)
			}
		}
	},
	mounted() {
		if (!this.isLongText) this.formatFullText()
		if (this.transformText) {
			this.textPreview = this.transformText(this.textPreview)
		}
	}
}
</script>

<style lang="scss">
.word-break_fade {
	position: relative;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: -32px;
		width: 32px;
		height: 20px;
		background: linear-gradient(to right, transparent, var(--primary) 80%, var(--primary) 100%);
	}
}
</style>
