<template functional>
	<div
		class="avatar-circle placeholder-bg"
		v-bind="data.attrs"
		v-on="listeners"
		:style="{ width: props.size, height: props.size, minWidth: props.size, minHeight: props.size }"
	>
		<img v-lazy="props.imagePath" src="#" alt="" />
	</div>
</template>

<script>
export default {
	name: 'AvatarCircle',
	props: {
		imagePath: {
			type: String,
			required: true
		},
		size: {
			type: String,
			default: '40px'
		}
	}
}
</script>

<style lang="scss">
.avatar-circle {
	border-radius: 100%;
	background-size: 100%;
	overflow: hidden;

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
