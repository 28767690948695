<template>
	<div class="schedule-list">
		<div v-if="isLoading">
			<div class="total placeholder-bg" />
		</div>
		<div v-else>
			<div v-if="scheduleByDay?.length" class="total">
				Запланировано публикаций: <span class="count">{{ scheduleByDay?.length }}</span>
			</div>
			<div v-else class="none">В этот день нет запланированных публикаций</div>
		</div>
		<div v-if="isLoading">
			<placeholder-schedule-events v-for="i in 3" :key="`pse${i}`" />
		</div>
		<div v-else v-for="(item, index) in scheduleByDay" :key="item.id">
			<div :class="['schedule-item', `schedule-item--${item.publication_status}`]">
				<div class="schedule-item-time">{{ item.should_be_published_at | moment('HH:mm') }}</div>
				<div
					:data-publication-status="item.publication_status"
					@click="goToDetailPage(item.publication_status, item.id)"
					class="schedule-body"
				>
					<div class="schedule-img-wrap">
						<img
							alt=""
							:src="
								item?.attachments?.photos
									? item?.attachments?.photos[0].thumbnail
									: item?.attachments?.video?.preview?.thumbnail
							"
							class="schedule-img"
						/>
						<svg-icon v-if="item?.attachments?.type === 'video'" name="video" size="20px" />
					</div>
					<div class="schedule-body-descr">
						<div class="schedule-body-top">
							<div
								class="schedule-body-status"
								@click="item.publication_status === 'rejected' ? rejectDialogClick(index) : null"
							>
								{{ getStatus(item.publication_status) }}
							</div>
							<div v-if="showMore" class="more" @click="(e) => actionsDialogClick(e, item)" />
						</div>
						<div v-if="item.audience === 'fans'" class="for-fans"><span class="name-badge" />Для фанатов</div>
						<div class="schedule-body-text">
							<text-with-hashtags-and-mentioned
								v-if="item.description"
								class="post-description"
								:preview-letters-count="60"
								:text="item.description"
								:hashtags="item?.hashtags"
								:mentioned="item?.mentioned"
								:expand-text="false"
							/>
							<div v-else class="text-default">Пост без описания</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<dialog-component v-if="showRejectDialog" @close="showRejectDialog = false">
			<template #header-title>Пост отклонен</template>
			<div class="text-red mb-16">{{ scheduleByDay[itemIndex]?.rejection_reason }}</div>
			<div>Для повторной модерации необходимо отредактировать пост</div>
		</dialog-component>
		<dialog-component v-if="showActionsDialog" @close="showActionsDialog = false">
			<div @click="onEdit" class="dialog-link">Редактировать</div>
			<div
				@click="confirmDeletePost()"
				class="dialog-link dialog-link--red"
				v-if="postItem.publication_status === 'rejected'"
			>
				Удалить пост
			</div>
		</dialog-component>
		<dialog-component v-if="showDeleteDialog" @close="showDeleteDialog = false">
			<div>Вы уверенны, что хотите удалить пост?</div>
			<ButtonComponent :loading="isSending" @click="onDelete" color="primary-outline mt-20"> Удалить </ButtonComponent>
		</dialog-component>
	</div>
</template>

<script>
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapActions } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'
import { usePostStore } from '@/stores/post'
import SvgIcon from '@/components/SvgIcon.vue'
import PlaceholderScheduleEvents from '@/components/placeholders/PlaceholderScheduleEvents.vue'

export default {
	name: 'ScheduleDayEvents',
	components: {
		ButtonComponent,
		DialogComponent,
		SvgIcon,
		TextWithHashtagsAndMentioned,
		PlaceholderScheduleEvents
	},
	props: {
		scheduleByDay: { type: Array, default: () => [] },
		showMore: { type: Boolean, default: true },
		isLoading: { type: Boolean, default: false }
	},
	data() {
		return {
			showRejectDialog: false,
			showActionsDialog: false,
			showDeleteDialog: false,
			isSending: false,
			itemIndex: null,
			postItem: null
		}
	},
	computed: {
		type() {
			if (
				this.postItem?.attachments &&
				this.postItem?.attachments?.photos &&
				this.postItem?.attachments?.type === 'photo'
			) {
				return this.postItem?.attachments?.photos.length > 1 ? 'carousel' : 'image'
			}
			return 'video'
		}
	},
	methods: {
		...mapActions(useCreatePostStore, { setPost: 'setPost' }),
		...mapActions(usePostStore, { deletePost: 'deletePost' }),
		getStatus(status) {
			const statuses = {
				in_moderation: 'На модерации',
				published: 'Опубликован',
				rejected: 'Отклонен',
				scheduled: 'Подтвержден',
				default: ''
			}
			return statuses[status] || statuses.default
		},
		goToDetailPage(status, id) {
			if (status === 'published') {
				this.$router.push(this.postPage(id))
			}
		},
		rejectDialogClick(index) {
			this.showRejectDialog = true
			this.itemIndex = index
		},
		actionsDialogClick(e, postItem) {
			e.preventDefault()
			e.stopPropagation()
			this.postItem = postItem
			this.showActionsDialog = true
		},
		confirmDeletePost() {
			this.showDeleteDialog = true
		},
		postPage(postId) {
			return { name: 'post', params: { id: postId } }
		},
		onEdit() {
			let post = {}
			if (this.type === 'image') {
				post = {
					photo_id: this.postItem?.attachments?.photos[0]?.id,
					photo: this.postItem?.attachments?.photos[0]?.url,
					description: this.postItem?.description,
					audience: this.postItem?.audience,
					should_be_published_at: this.postItem?.should_be_published_at,
					can_be_scheduled: this.postItem?.can_be_scheduled
				}
			}
			if (this.type === 'video') {
				post = {
					video_id: this.postItem?.attachments?.video?.id,
					video: this.postItem?.attachments?.video?.file,
					video_preview_id: this.postItem?.attachments?.video?.preview?.id,
					description: this.postItem?.description,
					audience: this.postItem?.audience,
					should_be_published_at: this.postItem?.should_be_published_at,
					can_be_scheduled: this.postItem?.can_be_scheduled
				}
			}
			this.setPost(post, this.postItem?.id, 'edit')
			this.showActionsDialog = false
		},
		async onDelete() {
			this.isSending = true
			await this.deletePost(this.postItem?.id)
			this.showDeleteDialog = false
			this.isSending = false
		}
	}
}
</script>

<style lang="scss">
.schedule-list {
	& .total {
		margin: 15px 0;
		font-size: 16px;
		color: var(--text-gray);
		& span {
			color: var(--white);
		}
		&.placeholder-bg {
			width: 100%;
			height: 20px;
		}
	}
	& .none {
		text-align: center;
		padding: 15px 0;
		font-size: 16px;
		color: var(--text-gray);
	}
	& .schedule-item {
		padding-bottom: 10px;
		&-time {
			font-size: 16px;
			color: var(--white);
			margin-bottom: 4px;
			&.placeholder-bg {
				width: 15%;
				height: 20px;
			}
		}
		& .schedule-body {
			display: flex;
			padding: 8px;
			background-color: #1f2128;
			border-radius: 11px;
			min-height: 104px;
			& .schedule-img-wrap {
				position: relative;
				margin-right: 10px;
				min-width: 84px;
				& .schedule-img {
					object-fit: cover;
					max-width: 84px;
					min-width: 84px;
					max-height: 84px;
					border-radius: 8px;
				}
				& .svg-icon {
					position: absolute;
					top: 6px;
					right: 8px;
				}
			}
			&-descr {
				flex: 1 1 100%;
			}
			&-top {
				display: flex;
				justify-content: space-between;
				margin-bottom: 7px;
			}
			&-status {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: var(--semi-transparent);
				& img {
					margin-left: 5px;
				}
				&.placeholder-bg-in {
					width: 45%;
					height: 17px;
				}
			}
			& .for-fans {
				display: inline-flex;
				align-items: center;
				font-size: 12px;
				padding: 2px 5px 2px 0;
				margin-bottom: 8px;
				background-color: #1553f2;
				border-radius: 10px;
			}
			&-text {
				font-size: 14px;
				color: var(--white);
				& .post-description {
					padding: 0;
					line-height: 14px;
				}
				&.placeholder-bg-in {
					width: 100%;
					height: 17px;
					&.second {
						margin-top: 5px;
						width: 85%;
					}
				}
			}
			& .text-default {
				color: var(--semi-transparent);
			}
		}
		&--published {
			& .schedule-item-time {
				color: var(--semi-transparent);
			}
			& .schedule-body-status {
				color: var(--white);
			}
		}
		&--scheduled {
			& .schedule-body-status {
				color: #2ba563;
			}
		}
		&--rejected {
			& .schedule-body-status {
				color: var(--red);
			}
		}
	}
	.placeholder-bg {
		border-radius: 6px;
	}
	.placeholder-bg-in {
		border-radius: 6px;
	}
}
.word-break_fade::before {
	background: linear-gradient(to right, transparent, #1f2128 80%, #1f2128 100%);
}
.dialog-link {
	display: block;
	padding: 10px 0;
	font-size: 16px;
	font-weight: 500;
	color: #ffffff;
}
.dialog-link--red {
	color: var(--red);
}
</style>
