function auto() {
	const scrollY = Math.abs(document.body.style.top.replace(/px/gi, ''))
	document.body.style.overflow = 'auto'
	document.documentElement.style.overflow = 'auto'
	document.body.style.touchAction = 'auto'
	document.body.style.position = ''
	document.body.style.top = ''
	document.body.style.left = ''
	document.body.style.right = ''
	window.scrollTo({ top: scrollY })
}

function hidden(scrollY = 0) {
	document.body.style.overflow = 'hidden'
	document.documentElement.style.overflow = 'hidden'
	document.body.style.touchAction = 'none'
	document.body.style.position = 'fixed'
	document.body.style.top = `-${scrollY}px`
	document.body.style.left = '0'
	document.body.style.right = '0'
}

export default { auto, hidden }
