<template>
	<div ref="scrollComponent">
		<slot />
		<div v-if="isLoading" class="pagination-loader">
			<i class="i-spinner rotating" />
		</div>
		<div v-if="isNetworkError" class="container">
			<ButtonComponent color="transparent" @click="retryLoad" :loading="isLoading"> Перезагрузить </ButtonComponent>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useModalStore } from '@/stores/modal'
import ScrollKeeper from '@/helpers/scrollKeeper'
// import Loader from '@/components/Loader.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

const scrollKeeper = new ScrollKeeper()

export default {
	name: 'InfiniteScroll',
	components: {
		// Loader,
		ButtonComponent
	},
	props: {
		loadFunction: {
			type: Function,
			required: true
		},
		elementsToTheEnd: {
			type: Number,
			default: 5
		},
		saveScroll: {
			type: Boolean,
			default: false
		},
		showPWA: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			elementHeight: null,
			isNetworkError: false,
			isLoading: false,
			itFirstPage: true
			// forceToTopRoutes: ['author-profile']
		}
	},
	computed: {
		routeName() {
			let routeName = this.$route.name
			const userId = this.$route.params.id
			if (userId) {
				routeName = `${routeName}/${userId}`
			}
			return routeName
		}
	},
	methods: {
		...mapActions(useModalStore, ['openPWADialog']),
		getElementHeight() {
			const { scrollComponent } = this.$refs
			const firstChild = scrollComponent.children[0]
			if (firstChild) {
				this.elementHeight = firstChild.clientHeight
			}
		},
		handleScroll() {
			if (!this.elementHeight) this.getElementHeight()
			const scrollHeight = Math.max(
				document.body.scrollHeight,
				document.documentElement.scrollHeight,
				document.body.offsetHeight,
				document.documentElement.offsetHeight,
				document.body.clientHeight,
				document.documentElement.clientHeight
			)

			const scroll = window.scrollY
			const pixelsLeft = scrollHeight - window.innerHeight - scroll

			if (this.saveScroll) {
				scrollKeeper.saveScroll(this.routeName, scroll)
			}

			if (pixelsLeft < this.elementHeight * this.elementsToTheEnd) {
				this.loadMoreData()
			}
		},
		async loadMoreData() {
			if (!this.itFirstPage && this.showPWA) {
				this.openPWADialog()
			}
			if (!this.isLoading && !this.isNetworkError) {
				this.isLoading = true
				this.itFirstPage = false
				try {
					await this.loadFunction()
					this.isNetworkError = false
				} catch (error) {
					if (error.code === 'ERR_NETWORK') {
						this.isNetworkError = true
					}
				} finally {
					this.isLoading = false
				}
			}
		},
		retryLoad() {
			this.isNetworkError = false
			this.loadMoreData()
		}
	},
	mounted() {
		if (this.saveScroll && !scrollKeeper.getSavedScroll(this.routeName)) {
			scrollKeeper.saveScroll(this.routeName, 0)
		}
		window.addEventListener('scroll', this.handleScroll)
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll)
	}
}
</script>

<style>
.pagination-loader {
	padding: 20px;
	display: flex;
	justify-content: center;
	font-size: 48px;
	color: #6a6a78;
	width: 100%;
}
</style>
