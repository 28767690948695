<template>
	<div>
		<div ref="swiper" class="swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(image, i) in photos" :key="image + i">
					<img
						:data-src="image.url"
						:width="image.width"
						:height="image.height"
						class="swiper-lazy w-full placeholder-bg"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div class="post-media-meta">{{ currentItem }}/{{ photos.length }}</div>
	</div>
</template>

<script>
import { Swiper, Lazy } from 'swiper'
import 'swiper/swiper.min.css'

Swiper.use([Lazy])

export default {
	name: 'PostMediaCarousel',
	props: {
		photos: { type: Array, default: () => [] }
	},
	data() {
		return {
			currentItem: 1
		}
	},
	methods: {
		changeCounter(slideIndex) {
			this.currentItem = slideIndex + 1
		}
	},
	mounted() {
		const swiper = new Swiper(this.$refs.swiper, {
			preloadImages: true,
			lazy: {
				loadPrevNext: true
			}
		})
		swiper.on('slideChange', (e) => {
			this.changeCounter(e.activeIndex)
		})
	}
}
</script>
