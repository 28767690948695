<template>
	<div class="post-like">
		<div class="post-like-icon" @click.prevent="onLike">
			<img v-if="amount" src="@/assets/images/icons/heart-active.png" class="post-like-heart" alt="" />
			<svg-icon v-else name="like" size="21px" />
		</div>
		<span v-if="amount" class="post-like-text">{{ amount }}</span>
	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { useModalStore } from '@/stores/modal'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'PostLike',
	components: {
		SvgIcon
	},
	props: {
		amount: { type: Number, required: true },
		postId: { type: Number, default: 0 }
	},
	methods: {
		...mapActions(useProfileStore, ['setLike']),
		...mapActions(useModalStore, ['showBalanceNotification']),
		async onLike() {
			try {
				await this.setLike(this.postId)
				this.$emit('clickLike')
			} catch (error) {
				this.showBalanceNotification()
			}
		}
	}
}
</script>

<style lang="scss">
.post-like {
	display: flex;
	align-items: center;
	cursor: pointer;

	&-icon {
		width: 22px;
		height: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-heart {
		height: 20px;
		width: auto;
	}

	&-text {
		margin-left: 6px;
	}
}
</style>
