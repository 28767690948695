<template>
	<div class="logo" @click="resetFilterAndScroll">
		<img :src="logoUrl" alt="" />
	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useHomeStore } from '@/stores/home'
import logo from '@/assets/images/logo.png'

export default {
	name: 'HeaderLogo',
	data() {
		return {
			logoUrl: import.meta.env.VITE_LOGO_URL || logo
		}
	},
	methods: {
		...mapActions(useHomeStore, ['resetFilter']),
		resetFilterAndScroll() {
			this.resetFilter()
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		}
	}
}
</script>
