<template>
	<div>
		<header-component />
		<div class="content padding-t" :class="{ 'padding-t-l': balanceNotification }">
			<slot />
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import HeaderComponent from '@/components/HeaderComponent.vue'

export default {
	name: 'BalanceOnly',
	components: { HeaderComponent },
	computed: {
		...mapState(useModalStore, ['balanceNotification'])
	}
}
</script>
